import {
    IonContent,
    IonPage,
    IonItem,
    IonLabel,
    IonText,
    IonInput,
    IonButton,
    NavContext,
    IonToast,
    IonLoading,
} from "@ionic/react";
import "./Login.css";
import { useForm } from "react-hook-form";
import { useContext, useState } from "react";
import { useMutation } from '@apollo/client';
import { LOGIN } from '../queries/auth';

type LoginForm = {
    email: string;
    password: string;
};

const Login: React.FC = () => {
    const { register, handleSubmit } = useForm();
    const { navigate } = useContext(NavContext);
    const [loginFailed, showLoginFailed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [login] = useMutation(LOGIN, {
        onCompleted: (data => {
            if (data.login) {
                localStorage.setItem("token", data.login);
                navigate("/Quotes/me");
            } else {
                showLoginFailed(true);
            }
            setIsLoading(false);
        }),
        onError: (error => {
            setIsLoading(false);
        })
    });

    const onLogin = async ({ email, password }: LoginForm) => {
        setIsLoading(true);
        login({ variables: { email, password } });
    };

    return (
        <IonPage>
            <IonContent fullscreen>
                <form className="container" onSubmit={handleSubmit(onLogin)}>
                    <IonText>
                        <h1>Connecte-toi</h1>
                    </IonText>
                    <IonItem>
                        <IonLabel position="floating">Login</IonLabel>
                        <IonInput
                            name="email"
                            ref={register({ required: true })}
                        ></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Password</IonLabel>
                        <IonInput
                            type="password"
                            name="password"
                            ref={register({ required: true })}
                        ></IonInput>
                    </IonItem>
                    <IonButton expand="block" type="submit" size="large">
                        Connexion
                    </IonButton>
                </form>
                <IonToast
                    isOpen={loginFailed}
                    onDidDismiss={() => showLoginFailed(false)}
                    message="Mot de passe ou login incorrect"
                    duration={600}
                />
                <IonLoading isOpen={isLoading} message={"Connexion..."} />
            </IonContent>
        </IonPage >
    );
};

export default Login;

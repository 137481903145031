import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonText,
    IonInput,
    IonButton,
    NavContext,
    IonToast,
    IonLoading,
} from "@ionic/react";
import { useParams } from "react-router";
import ExploreContainer from "../components/ExploreContainer";
import "./Login.css";
import { useForm } from "react-hook-form";
import Api from "../services/Api";
import { useContext, useState } from "react";

type SignupForm = {
    email: string;
    username: string;
    password: string;
};

const Signup: React.FC = () => {
    const { name } = useParams<{ name: string }>();
    const { register, handleSubmit } = useForm();
    const { navigate } = useContext(NavContext);
    const [loginFailed, showLoginFailed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const onLogin = async (userData: SignupForm) => {
        setIsLoading(true);
        const api = Api.getInstance();
        const { data } = await api.call(
            `mutation { login(email:"${userData.email}", password: "${userData.password}") }`
        );
        setIsLoading(false);

        if (data.login) {
            api.storeAuthToken(data.login);
            navigate("/Quotes/me");
        } else {
            showLoginFailed(true);
        }
    };

    return (
        <IonPage>
            <IonContent fullscreen>
                <form className="container" onSubmit={handleSubmit(onLogin)}>
                    <IonText>
                        <h1>Connecte-toi</h1>
                    </IonText>
                    <IonItem>
                        <IonLabel position="floating">Login</IonLabel>
                        <IonInput
                            name="email"
                            ref={register({ required: true })}
                        ></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Password</IonLabel>
                        <IonInput
                            type="password"
                            name="password"
                            ref={register({ required: true })}
                        ></IonInput>
                    </IonItem>
                    <IonButton expand="block" type="submit" size="large">
                        S'inscrire
                    </IonButton>
                </form>
                <IonToast
                    isOpen={loginFailed}
                    onDidDismiss={() => showLoginFailed(false)}
                    message="Mot de passe ou login incorrect"
                    duration={600}
                />
                <IonLoading isOpen={isLoading} message={"Inscription..."} />
            </IonContent>
        </IonPage>
    );
};

export default Signup;

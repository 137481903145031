export default class Api {
    static instance: Api;
    readonly ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT ?? "";
    readonly HEADERS = {
        "Content-Type": "application/json",
        Accept: "application/json",
    };

    async call(query: string) {
        const response = await fetch(this.ENDPOINT, {
            method: "POST",
            headers: this.resolveHeaders(),
            body: JSON.stringify({ query }),
        });

        return response.json();
    }

    resolveHeaders(): Headers {
        const headers = new Headers(this.HEADERS);
        const authToken = localStorage.getItem("token");
        if (authToken) {
            headers.set("Authorization", `Bearer ${authToken}`);
        }

        return headers;
    }

    storeAuthToken(token: string) {
        localStorage.setItem("token", token);
    }

    static getInstance() {
        if (!this.instance) {
            Api.instance = new Api();
        }

        return this.instance;
    }
}

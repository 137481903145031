import { gql } from "@apollo/client";

export const MY_QUOTES = gql`
  query myQuotes {
    myQuotes {
      id
      author
      date
      location
      text
    }
  }
`;

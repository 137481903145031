import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonIcon,
} from "@ionic/react";
import { location as locationIcon } from "ionicons/icons";
import { Quote } from "../models/Quote";
import "./QuoteCard.scss";

interface QuoteProps {
    quote: Quote;
}

const QuoteCard: React.FC<Quote> = ({ author, text, date, location }) => {
    return (
        <IonCard>
            <IonCardContent>
                <h1 className="quote-content">{text}</h1>
            </IonCardContent>
            <IonCardHeader className="ion-text-end">
                <IonCardTitle>{author}</IonCardTitle>
                <IonCardSubtitle>
                    <IonIcon icon={locationIcon} /> <b>{location}</b> &ndash;{" "}
                    {new Date(date).getFullYear()} AC.
                </IonCardSubtitle>
            </IonCardHeader>
        </IonCard>
    );
};

export default QuoteCard;

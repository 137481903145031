import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import {
    bookmarkOutline,
    mailOutline,
    mailSharp,
    paperPlaneOutline,
    paperPlaneSharp,
} from "ionicons/icons";
import "./Menu.css";

interface AppPage {
    url: string;
    title: string;
}

const appPages: AppPage[] = [
    {
        title: "My Quotes",
        url: "/Quotes/me",
    },
    {
        title: "Friends",
        url: "/Quotes/friends",
    },
    {
        title: "Recommended",
        url: "/Quotes/friends",
    },
];

const tags = ["Touching", "Funny", "Surprising", "Private"];

const Menu: React.FC = () => {
    const location = useLocation();

    return (
        <IonMenu contentId="main" type="overlay">
            <IonContent>
                <IonList id="inbox-list">
                    <IonListHeader>QuoteMyBro</IonListHeader>
                    <IonNote>hi@ionicframework.com</IonNote>
                    {appPages.map((appPage, index) => {
                        return (
                            <IonMenuToggle key={index} autoHide={false}>
                                <IonItem
                                    className={
                                        location.pathname === appPage.url
                                            ? "selected"
                                            : ""
                                    }
                                    routerLink={appPage.url}
                                    routerDirection="none"
                                    lines="none"
                                    detail={false}
                                >
                                    <IonLabel>{appPage.title}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        );
                    })}
                </IonList>

                <IonList id="labels-list">
                    <IonListHeader>Tags</IonListHeader>
                    {tags.map((label, index) => (
                        <IonItem lines="none" key={index}>
                            <IonIcon slot="start" icon={bookmarkOutline} />
                            <IonLabel>{label}</IonLabel>
                        </IonItem>
                    ))}
                </IonList>
            </IonContent>
        </IonMenu>
    );
};

export default Menu;

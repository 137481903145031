import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Menu from "./components/Menu";
import Page from "./pages/Page";
import Login from "./pages/Login";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Quotes from "./pages/Quotes";
import Signup from "./pages/Signup";

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

const App: React.FC = () => {
    return (
        <IonApp>
            <IonReactRouter>
                <ApolloProvider client={client}>
                    <IonSplitPane contentId="main">
                        <Menu />
                        <IonRouterOutlet id="main">
                            <Route path="/Login" component={Login} exact={true} />
                            <Route path="/Signup" component={Signup} exact={true} />
                            <Route path="/Quotes/:feed" component={Quotes} />
                            <Route path="/page/:name" exact={true}>
                                <Page />
                            </Route>
                            <Route path="/" exact={true}>
                                <Redirect to="/Login" />
                            </Route>
                        </IonRouterOutlet>
                    </IonSplitPane>
                </ApolloProvider>
            </IonReactRouter>
        </IonApp>
    );
};

export default App;

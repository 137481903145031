import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    useIonViewDidEnter,
} from "@ionic/react";
import { useParams } from "react-router";
import QuoteCard from "../components/QuoteCard";
import "./Quotes.scss";
import Api from "../services/Api";
import { useEffect, useState } from "react";
import { Quote } from "../models/Quote";
import { MY_QUOTES } from "../queries/quotes";
import { useQuery } from "@apollo/client";

const Quotes: React.FC = () => {
    const { name } = useParams<{ name: string }>();
    const { loading, error, data } = useQuery(MY_QUOTES);

    if (loading) {
        return <h1>Loading</h1>;
    }

    if (error) {
        return <h1>Error</h1>
    }

    const quotes = data.myQuotes;

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>{name}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                {quotes.map((quote: Quote) => (
                    <QuoteCard {...quote} key={quote.id} />
                ))}
            </IonContent>
        </IonPage>
    );
};

export default Quotes;
